export default {
  advantages: "优势",
  pricing: "价格方案",
  contacts: "联系我们",
  download: "下载",
  login: "登录",
  "sign.up": "注册",
  title: "指纹浏览器",
  desc: "进行流量套利，通过",
  desc2: "主要为团队合作和流程自动化而创建",
  "try.for.free": "免费试用",
  advantages1: "Mogic37 的指纹浏览器好处",
  "advantages.title": "管理浏览器配置文件",
  "advantages.desc":
    "通过一台设备处理数百个唯一的配置文件。 每个配置文件都是具有独立Web环境的唯一浏览器指纹。 缓存，Cookie和其他参数彼此完全分开",
  "advantages.title1": "真实指纹浏览器配置文件",
  "advantages.desc1":
    "通过 Mogic37 能在因特网中普通用户的名下进行最逼真的伪装。",
  "advantages.title2": "协同工作",
  "advantages.desc2":
    "现代的防检测浏览器不仅需要高质量的替代品，而且还需要便捷的团队互动，跟踪帐户，注释和代理的状态。 我们已经认真研究了此功能。",
  support: "支持",
  legal: "法律声明",
  pp: "隐私保护政策",
  tos: "合同建议书",
  'contact.title': '保持联系 Mogic37 指纹浏览器的更新和功能',
  'contact.title1': 'Telegram 频道',
  'contact.desc1': '保持联系 Mogic37 指纹浏览器的更新和功能',
  'contact.title2': 'Telegram 群',
  'contact.desc2': '在Telegram群中我们团结社区并分享经验',
  'contact.title3': '技术支持',
  'contact.desc3': '帮助用户处理与 Mogic37 有关的问题',
  'pricing.desc': '使用 20％ 的折扣并支付 6 个月',
  'free': '免费版',
  'free.desc': '全部指纹管理功能',
  'base': '普通版',
  'base.desc': '为小型团队',
  'team': '团队版',
  'team.desc': '适合中型团队的协作工具',
  'enterprise': '专业版',
  'enterprise.desc': '全部指功能。适合大型团队',
  'monthly':'按月购买',
  'half': '按半年购买',
  'save.profiles': '保存多达 {{num}} 个浏览器配置文件',
  'ability1': '+ 可添加团队成员',
  'ability2': '+ 可以购物虚拟浏览器环境',
  'additional1': '每个额外的用户',
  'additional2': '每 10 个指纹浏览器配置文件 （最多50）',
  'additional3': '每个 1000 指纹浏览器配置文件',
  'month': '每月',
  'functions': '所有计划的基本功能',
  'functions1': '在所有计算机上同步浏览器配置文件',
  'functions2': '全套浏览器指纹',
  'functions3': '当新版本的Chrome浏览器发布时快速更新浏览器',
  'functions4': '批量配置文件管理工具 ',
  'functions5': '实时IP地理位置检测',
  'functions6': '先进的团队合作工具',
  'functions7': '我们正在开发的功能。。。',
  'functions8': '与 Mogic37 产品集成',
  'functions9': '与流行的代理提供商集成',
  'Compatibility Notes': '兼容性说明',
  'compativility.tip': '兼容Windows 8及更高版本（至少双核CPU、2G RAM和2G可用磁盘空间）',
  'product.desc': '与控制台进行交互，用于管理本地浏览器环境。',
  'developing': '开发中',
  'mac.download.tip': '兼容 macOS 11 及更高版本（至少双核CPU、2G RAM和2G可用磁盘空间）',
  'not.allow.register': '暂未开放注册，请联系 feedback@mogic37.com'
};
