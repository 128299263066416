export default {
    advantages: "Ưu điểm",
    pricing: "Giá cả",
    contacts: "Liên hệ chúng tôi",
    download: "Tải xuống",
    login: "Đăng nhập",
    "sign.up": "Đăng ký",
    title: "Trình duyệt chống phát hiện",
    desc: "dành cho mục đích tiếp thị liên kết trên",
    desc2: "Chủ yếu được tạo ra cho công việc nhóm và tự động hóa quy trình",
    "try.for.free": "Dùng thử miễn phí",
    "advantages1": 'Ưu điểm của Mogic37',
    "advantages.title": 'Quản lý hồ sơ trình duyệt',
    "advantages.desc": 'Làm việc với hàng trăm hồ sơ duy nhất từ một thiết bị. Mỗi hồ sơ là một dấu vết trình duyệt duy nhất với một môi trường web riêng biệt. Bộ nhớ cache, cookie và các tham số khác hoàn toàn được phân tách ra khỏi nhau',
    "advantages.title1": 'Hồ sơ trình duyệt dấu vết thực sự',
    "advantages.desc1": "Bạn sẽ giống như một người dùng internet thông thường nhất có thể. Chúng tôi thu thập dấu vết từ người dùng thực sự và cung cấp chúng cho bạn khi bạn tạo hồ sơ trình duyệt mới.",
    "advantages.title2": 'Làm việc nhóm',
    "advantages.desc2": 'Một phần mềm chống phát hiện hiện đại không chỉ yêu cầu một sự thay thế chất lượng cao, mà còn yêu cầu sự tương tác thuận tiện trong nhóm, theo dõi trạng thái của tài khoản, ghi chú và proxy. Chúng tôi đã nghiêm túc làm việc trên chức năng này.',
    "support": 'Hỗ trợ',
    'legal': 'Pháp lý',
    'pp': 'Chính sách bảo mật',
    'tos': 'Điều khoản dịch vụ',
    'contact.title': 'Liên hệ chúng tôi',
    'contact.title1': 'Kênh Telegram',
    'contact.desc1': 'Giữ liên lạc về các bản cập nhật và tính năng của Mogic37',
    'contact.title2': 'Hỗ trợ trực tuyến',
    'contact.desc2': 'Giúp người dùng giải quyết vấn đề liên quan đến Mogic37',
    'contact.title3': 'Trò chuyện Telegram',
    'contact.desc3': 'Ở đây chúng tôi hợp nhất cộng đồng và chia sẻ kinh nghiệm',
    'pricing.desc': 'Sử dụng giảm giá 20% và trả tiền cho 6 tháng',
    'free': 'Miễn phí',
    'free.desc': 'Miễn phí với tất cả các tính năng',
    'base': 'Cơ bản',
    'base.desc': 'Hợp tác cho một nhóm nhỏ',
    'team': 'Nhóm',
    'team.desc': 'Lợi nhuận cho một nhóm nhỏ',
    'enterprise': 'Doanh nghiệp',
    'enterprise.desc': 'Chức năng đầy đủ cho một nhóm lớn',
    'monthly': 'Hàng tháng',
    'half': 'Nửa năm',
    'save.profiles': 'Lưu lên đến {{num}} hồ sơ trình duyệt',
    'ability1': '+ Khả năng thêm người dùng vào nhóm',
    'ability2': '+ Khả năng mua hồ sơ bổ sung',
    'additional1': 'cho thành viên nhóm bổ sung',
    'additional2': 'cho mỗi 10 hồ sơ bổ sung (tối đa 50)',
    'additional3': 'cho mỗi 1000 hồ sơ bổ sung',
    'month': 'Tháng',
    'functions': 'Chức năng cơ bản của tất cả các kế hoạch',
    'functions1': 'Đồng bộ hóa hồ sơ trình duyệt trên tất cả máy tính',
    'functions2': 'Bộ đặt dấu vết trình duyệt giả mạo đầy đủ',
    'functions3': 'Cập nhật trình duyệt nhanh khi có phiên bản mới của Chrome được phát hành',
    'functions4': 'Công cụ quản lý hồ sơ hàng loạt',
    'functions5': 'Phát hiện Vị trí địa lý IP thời gian thực',
    'functions6': 'Công cụ làm việc nhóm tinh vi',
    'functions7': 'Chức năng chúng tôi đang làm việc trên',
    'functions8': 'Tích hợp với sản phẩm Mogic37',
    'functions9': 'Tích hợp với các nhà cung cấp proxy phổ biến',
    'Compatibility Notes': 'Ghi chú tương thích',
    'compativility.tip': 'Tương thích với Windows 8 và các phiên bản mới hơn (yêu cầu tối thiểu là CPU hai nhân, 2G RAM và 2G dung lượng đĩa trống)',
    'product.desc': 'Tương tác với bảng điều khiển để quản lý môi trường trình duyệt cục bộ.',
    'not.allow.register':'Đăng ký chưa được mở, vui lòng liên hệ phản hồi@mogic37.com'
}
