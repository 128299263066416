export default {
  advantages: "Преимущества",
  pricing: "Тарифы",
  contacts: "Контакты",
  download: "Скачать",
  login: "Войти",
  "sign.up": "Регистрация",
  title: "Антидетект браузер",
  desc: "для задач арбитража трафика в",
  desc2: "Заточенный под командную работу и автоматизацию процессов",
  "try.for.free": "Зарегистрироваться",
  "advantages1": 'Преимущества Mogic37',
  "advantages.title": 'Управляй браузерными профилями',
  "advantages.desc": 'Работай с&nbsp;сотнями уникальных профилей с&nbsp;одного устройства. Каждый профиль&nbsp;— это уникальный браузерный отпечаток с&nbsp;отдельной средой для работы в&nbsp;интернете. Кэш, куки-файлы и&nbsp;другие параметры полностью отделены друг от&nbsp;друга',
  "advantages.title1": 'Реальные отпечатки браузерных профилей',
  "advantages.desc1": "Вы будете максимально похожи на обычного пользователя интернета. Мы собираем отпечатки fingerprint c реальных пользователей и отдаем их вам при создании новых браузеров",
  "advantages.title2": 'Командная работа',
  "advantages.desc2": 'Современный антидетект требует не только качественный подмен, но и удобное взаимодействие в команде, отслеживание статусов аккаунтов, заметок и прокси. Мы серьёзно проработали данный функционал',
  "support": 'Поддержка',
  'legal': 'Документы',
  'pp': 'Политика конфиденциальности',
  'tos': 'Условия использования',
  'contact.title': 'Свяжись с нами',
  'contact.title1': 'Телеграм-канал',
  'contact.desc1': 'Рассказываем об апдейтах и фишках Mogic37',
  'contact.title2': 'Тех. поддержка',
  'contact.desc2': 'Помогаем разбираться с проблемами, связанными с Mogic37',
  'contact.title3': 'Чат в Телеграме',
  'contact.desc3': 'Объединяем комьюнити и делимся опытом',
  'pricing.desc': 'Использовать скидку 20% и оплатить на 6 месяцев',
  'free': 'Free',
  'free.desc': 'Бесплатный тариф с полным набором подмен',
  'base': 'Base',
  'base.desc': 'Совместная работа для небольшой команды',
  'team': 'Team',
  'team.desc': 'Выгодный тариф для небольшой команды',
  'enterprise': 'Enterprise',
  'enterprise.desc': 'Полный функционал для большой команды',
  'monthly': 'по месяцам',
  'half': 'полгода',
  'save.profiles': 'Сохранение до {{num}} браузерных профилей',
  'ability1': '+ Возможность добавлять пользователей в команду',
  'ability2': '+ Возможность докупать дополнительные профили',
  'additional1': 'за дополнительного пользователя',
  'additional2': 'за каждые 10 дополнительных профилей (максимум 50)',
  'additional3': '1000 браузерных профилей отпечатков пальцев каждый',
  'month': 'луна',
  'functions': 'Базовые функции всех тарифов',
  'functions1': 'Синхронизация профилей браузера на всех компьютерах',
  'functions2': 'Полный набор подмен fingerprint браузера',
  'functions3': 'Быстрое обновление ядра браузера при выходе новых версий Chrome',
  'functions4': 'Инструменты массового управления профилями',
  'functions5': 'Обнаружение IP-геолокации в режиме реал-тайм',
  'functions6': 'Проработанный инструментарий для командной работы',
  'functions7': 'Находятся в разработке',
  'functions8': 'Интеграция с продуктами Dolphin',
  'functions9': 'Интеграция с популярными прокси-провайдерами',
  'Compatibility Notes': 'Заметки о совместимости',
  'compativility.tip': 'Совместим с Windows 8 и более поздними версиями (минимальные требования: двухъядерный процессор, 2 ГБ ОЗУ и 2 ГБ свободного пространства на диске)',
  'product.desc': 'Взаимодействие с консолью для управления локальной средой браузера.',
  'not.allow.register': 'Регистрация еще не открыта, свяжитесь с нами по адресу Feedback@mogic37.com.'
};
