import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import initI18n from "./utils/i18n";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";
import { PrerenderedComponent } from "react-prerendered-component";
const prerenderedLoadable = dynamicImport => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo(props => (
    // you can use the `.preload()` method from react-loadable or react-imported-component`
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

const SaaSProductLandingPage = prerenderedLoadable(() =>
  import("./demos/SaaSProductLandingPage.js")
);
const PrivacyPolicy = prerenderedLoadable(() => import("./pages/PrivacyPolicy"));
const TermsOfService = prerenderedLoadable(() => import("./pages/TermsOfService"));
const Pricing = prerenderedLoadable(() => import("./pages/Pricing"));
const Download = prerenderedLoadable(() => import("./pages/Download"));

export default function App() {
  initI18n();
  return (
    <>
      <GlobalStyles />
      <Router>
          <Routes>
            <Route path="/" element={<SaaSProductLandingPage />} />
            <Route path="/price" element={<Pricing />} />
            <Route path="/pp" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/download" element={<Download />} />
          </Routes>
      </Router>
    </>
  );
}
