export default {
  advantages: "Advantages",
  pricing: "Pricing",
  contacts: "Contact Us",
  download: "Download",
  login: "Login",
  "sign.up": "Sign up",
  title: "Anti-detect browser",
  desc: "for affiliate marketing purposes on",
  desc2: "Principally created for teamwork and processes automation",
  "try.for.free": "Try for free",
  "advantages1": 'Mogic37 advantages',
  "advantages.title": 'Manage browser profiles',
  "advantages.desc": 'Work with hundreds of unique profiles from one device. Each profile is a unique browser fingerprint with a separate web environment. Cache, cookies and other parameters are completely separate from each other',
  "advantages.title1": 'Real fingerprint browser profiles',
  "advantages.desc1": "You'll look like a usual internet user as much as possible. We collect fingerprints from real users and give them to you when you create new browser profile.",
  "advantages.title2": 'Teamwork',
  "advantages.desc2": 'A modern antidetect requires not only a high-quality substitution, but also convenient team interaction, tracking the statuses of accounts, notes and proxies. We have seriously worked on this functionality.',
  "support": 'Support',
  'legal': 'Legal',
  'pp': 'Privacy policy',
  'tos': 'Terms of Service',
  'contact.title': 'Contact us',
  'contact.title1': 'Telegram channel',
  'contact.desc1': 'Keep in touch about updates and features of Mogic37',
  'contact.title2': 'Online support',
  'contact.desc2': 'Help users to handle with problems related with Mogic37',
  'contact.title3': 'Telegram chat',
  'contact.desc3': 'Here we unite the community and share experience',
  'pricing.desc': 'Use 20% discount and pay for 6 months',
  'free': 'Free',
  'free.desc': 'Free with all features',
  'base': 'Base',
  'base.desc': 'Collaboration for a small team',
  'team': 'Team',
  'team.desc': 'Profitable for a small team',
  'enterprise': 'Enterprise',
  'enterprise.desc': 'Full functionality for a large team',
  'monthly':'Monthly',
  'half': 'Half a year',
  'save.profiles': 'Save up to {{num}} browser profiles',
  'ability1': '+ Ability to add users to a team',
  'ability2': '+ Ability to buy additional profiles',
  'additional1': 'for additional team member',
  'additional2': 'for every 10 additional profiles (max 50)',
  'additional3': 'for every 1000 additional profiles',
  'month': 'Month',
  'functions': 'Basic functions of all plans',
  'functions1': 'Synchronization of browser profiles on all computers',
  'functions2': 'Full set of spoofed browser fingerprints',
  'functions3': 'Quick browser update when new versions of Chrome are released',
  'functions4': 'Bulk profile management tools',
  'functions5': 'Real-time IP Geolocation Detection',
  'functions6': 'Sophisticated teamwork tools',
  'functions7': 'Features we are working on',
  'functions8': 'Integration with Mogic37 products',
  'functions9': 'Integration with popular proxy providers',
  'Compatibility Notes': 'Compatibility Notes',
  'compativility.tip': 'It is compatible with Windows 8 and later (with a minimum of dual-core CPU, 2G of RAM, and 2G of free disk space)',
  'product.desc': 'Interact with the console for managing the local browser environment.',
  'developing': 'developing',
  'mac.download.tip': 'Compatible with macOS 11 and later (with a minimum of dual-core CPU, 2G of RAM, and 2G of free disk space)',
  'not.allow.register': 'Registration is not open yet, please contact feedback@mogic37.com'

  // 'tos': 'Terms of Service',
  // 'pp.title': 'Mogic37 PRIVACY POLICY',
  // 'pp1': 'WE RESPECT YOUR PRIVACY!',
  // 'pp2': 'If you have any privacy-related concerns, please contact us at:',
  // 'pp3': '1. GENERAL',
  // 'pp4': 'This Privacy Policy (“Policy”) describes the order in which Zhitnyakov Software Solutions LTD, a company registered under the laws of the Republic of Cyprus, having its address at Georgiou A`13, Stala Court off. 3, Germasogeia 4040, Limassol, Cyprus (“We”, “Us”), collects, uses and discloses information, when you access or use the “Mogic37” Browser (“Browser”), and our website at https://mogic37.com (“Website”) , or when you otherwise interact with Us.',
  // 'pp5': 'This Privacy Policy describes how We collect and use the Personal Data you provide. It also describes available options regarding Our use of your Personal Data and your access to information. We respect your privacy and We take its protection seriously.',
  // 'pp6': 'We may change this Policy at any time without notice to you. Changes take effect from the moment they are published in the Browser or on the Website.',
  // 'pp7': 'This Policy applies to the following people:',
  // 'pp8': 'people who use the Browser (for the purposes of this Policy, We define the term “User”, “you”, "your", "yours" as a person who installed Browser);',
  // 'pp9': 'people who make complaints to Us by email.',
  // 'pp10': 'We do not sell your personal data to third parties. If We decide to sell the Browser, We will inform you about it, so you can forbid Us to transfer your personal data together with our business. If so, We will delete your data from the databases prior to a business transfer.',
  // 'pp11': '2. PERSONAL DATA THAT WE COLLECT AND PROCESS',
  // 'pp12': 'In order to fulfill our obligations on provision of a license for Browser, We ask you to provide Us with your personal data, including (but not limited):',
  // 'pp13': 'personal email;',
  // 'pp14': 'login Telegram;',
  // 'pp15': 'IP address.',
  // 'pp16': 'Personal Data collected by or transmitted to Us in the course of accessing, interacting and operating of the Browser may include, without limitation, the following Personal Data:',
  // 'pp17': 'device information, which may include (but is not limited to) information about the personal computer or laptop you use to access the Browser, including the hardware model, operating system and version, the web browser you use, and other device identifiers;',
  // 'pp18': 'server log information, which may include (but is not limited to) your login details, the date and time of visits, the pages viewed, your IP address, time spent at the Browser;',
  // 'pp19': 'your logins and passwords;',
  // 'pp20': 'information collected by cookies and other tracking technologies (altogether - “Personal Data”).',
  // 'pp21': "We and our Browser providers use various technologies to collect information when you interact with the Browser, including cookies and web beacons. ‘Cookies’ are small data files that are stored on your device when you visit the Browser, which enables Us to collect information about your device identifiers, IP address, used to access the Browser, pages or features viewed, time spent on pages and links clicked. ‘Web beacons’ are graphic images that are placed on a website or in an email that is used to monitor the behavior of the user visiting the website or sending the email. They are often used in combination with cookies. We may also receive information about you from other sources, such as when you log in to Mogic37 by using your account credentials for a separate third-party Browser. We will receive information from that Browser as indicated in the relevant third party's authorization screen.",
  // 'pp22': 'We may collect Personal Data from you in a variety of ways and circumstances, including, but not limited to, installation of Browser, subscription to a newsletter, filling out a form, providing Us with feedback. We shall be entitled to combine Personal Data collected in the course of your interaction with different sections of the Browser with any other relevant available information.',
  // 'pp23': 'Processing of Usage Data relies on our legitimate interests. It is necessary for managing and running our business efficiently and effectively, providing quality services including Website support, developing and improving products, determining who may be interested in them.',
  // 'pp24': '3. PRINCIPLES',
  // 'pp25': 'We adhere to the following principles in order to protect your privacy:',
  // 'pp26': 'purposefulness',
  // 'pp27': 'We process personal data fairly and in a transparent manner only for the achievement lawful objectives specified in the Policy, and they shall not be processed in a manner not conforming to the objectives of data processing;',
  // 'pp28': 'minimalism',
  // 'pp29': 'We collect Personal Data only to the extent necessary for the achievement of determined purposes and do not keep Personal Data if it is no longer needed;',
  // 'pp30': 'restricted use',
  // 'pp31': 'We use Personal Data for other purposes only with the consent of the data subject or with the permission of a competent authority;',
  // 'pp32': 'data quality',
  // 'pp33': 'We update Personal Data shall be up-to-date, complete and necessary for the achievement of the purpose of data processing;',
  // 'pp34': 'security',
  // 'pp35': 'security measures shall be applied in order to protect Personal Data from unauthorized or unlawful processing and against accidental loss, destruction or damage, using appropriate technical and organizational measures;',
  // 'pp36': 'individual participation',
  // 'pp37': 'the persons shall be notified of their data collected, the persons shall be granted access to their Personal Data and the persons shall have the right to demand the correction of inaccurate or misleading data.',
  // 'pp38': '4. USE OF COLLECTED PERSONAL DATA',
  // 'pp39': 'We may use the information collected from you for a variety of purposes, primarily, relating to providing our Browser and information about it. We may also use the information for such other purposes as otherwise allowed by law. For example, We (or a supplier or our affiliate company acting on our behalf and only under our instructions) may use your Personal Data, including personally identifiable information, for such purposes, including, but not limited to:',
  // 'pp40': 'to provide license and access to Browser;',
  // 'pp41': 'to handle your orders and requests, including requests for technical support and assistance;',
  // 'pp42': 'to properly fulfill tax-related and accounting obligations imposed by applicable laws;',
  // 'pp43': 'to detect, investigate, and prevent illegal activities or conduct that may violate the Terms of Use of Browser and this Policy;',
  // 'pp44': 'personalize your experience with our Browser;',
  // 'pp45': 'contact you in relation to, and conduct, surveys or polls you choose to take part in and to analyze the data collected for market research purposes;',
  // 'pp46': 'conduct internal research and development and to improve, test and enhance the features and functions of our Browser;',
  // 'pp47': 'marketing and promotion of our Browser or related products, including those of a third party’s products which are related to our Browser (if you do not want Us to use your data in this way, please let Us know by contacting Us at',
  // 'pp48': 'meet our internal and external audit requirements, including our information security obligations;',
  // 'pp49': 'tracking purchases and usage information;',
  // 'pp50': 'any other purpose as We determine, in our sole discretion, to be necessary or required to ensure the safety and/or integrity of our users, employees, third parties, public, and/or our Browser, or to comply with requirements of any applicable law.',
  // 'pp51': '5. LEGAL BASIS FOR PROCESSING OF PERSONAL DATA',
  // 'pp52': 'CONSENT',
  // 'pp53': 'Consent of EU residents to the processing of personal data',
  // 'pp54': 'In order to process your personal data, We need to obtain your consent, as provided for by the GDPR, We will only process your personal data if We have received from you a freely expressed, specific, informed and unambiguous indication of your desire, by which you express your consent to the processing of your personal data (“Consent”).',
  // 'pp55': 'You can give your Consent by ticking the appropriate box when registering an account in our Browser. In the event that you check the appropriate box, you irrevocably and unconditionally consent to and agree that We have the right to process your Personal Data in accordance with this Policy.',
  // 'pp56': 'Your Consent applies to all activities related to the processing of your personal data that are carried out for the same purpose or purposes. When processing has multiple purposes, your Consent is deemed to have been given for all of them.',
  // 'pp57': 'You have the right to withdraw your Consent at any time. You may submit a request by sending Us an email at {email}. Your withdrawal of Consent will not affect the lawfulness of the processing of your Personal Data based on Consent prior to such withdrawal.',
  // 'pp58': 'Except as required or permitted by law, We will not use or disclose your personal data for any purpose for which you have refused to give your Consent or later withdrew your Consent. If you withdraw your Consent, you agree that, despite this withdrawal, We may continue to use the personal data previously provided to Us, to the extent that We are required to do so by contract or other legal grounds, and to the extent to the extent necessary to fulfill any contractual obligation you may have to Us, or in any other manner permitted by law.',
  // 'pp59': 'Consent to the processing of personal data of non-EU residents',
  // 'pp60': 'By ticking the box when registering an account in our Browser and by submitting your personal data to Us, you irrevocably and unconditionally consent and agree that We have the right, in accordance with this Policy to process in any way, including collection, storage, use, disclosure, exchange and transfer (including cross-border), your personal data provided to Us, as well as your personal data collected as a result of your use of the Service (i.e. your personal data that We collect automatically and/or from other sources).',
  // 'pp61': 'LEGITIMATE INTEREST',
  // 'pp62': 'The legitimate interest pursued by us to analyse our Website traffic in order to maintain the Service, for example monitoring successful page loads or error messages.',
  // 'pp63': 'PERFORMANCE OF THE CONTRACT',
  // 'pp64': 'When you purchase a subscription or buy another service from us (even if you just register in the Browser), we will need to process your details in order to provide you the services.',
  // 'pp65': '6. DATA ACCESS, DATA CORRECTION, DATA DELETION, DATA PORTABILITY AND WITHDRAWAL OF THE CONSENT',
  // 'pp66': 'You can review, correct, update, delete or transfer their personally identifiable information. For that, contact Us directly at {email}. We will acknowledge your request within 72 hours and handle it promptly and as required by law.',
  // 'pp67': 'Right to access. ',
  // 'pp68': 'You may contact Us to get confirmation as to whether or not We are processing your Personal Data. When We process your personal data, We will inform you of what categories of Personal Data We process regarding you, the processing purposes, the categories of recipients to whom Personal Data have been or will be disclosed and the envisaged storage period or criteria to determine that period.',
  // 'pp69': 'Right to withdraw consent.',
  // 'pp70': 'In case our processing is based on the Consent granted, you may withdraw the Consent at any time by contacting Us or by using the functionalities of our Browser. You can withdraw your Сonsent at any time by replying to the email with your withdrawal and your Personal Data will be deleted in 30 calendar days. Please pay attention that withdrawing Consent may lead to impossibility to use Browser.',
  // 'pp71': 'Right to object.',
  // 'pp72': 'In case our processing is based on our legitimate interest to run, maintain and develop our business, you have the right to object at any time to our processing. We shall then no longer process your Personal Data unless for the provision of our Browser or if We demonstrate other compelling legitimate grounds for our processing that override your interests, rights and freedoms or for legal claims. Notwithstanding any consent granted beforehand for direct marketing purposes, you have the right to prohibit Us from using Personal Data for direct marketing purposes, by contacting Us or by using the functionalities of the Browser or unsubscribe possibilities in connection with our direct marketing messages.',
  // 'pp73': 'Right to restriction of the processing.',
  // 'pp74': 'You have the right to obtain from Us restriction of processing of your Personal Data, as foreseen by applicable data protection law, e.g. to allow our verification of accuracy of Personal Data after your contesting of accuracy or to prevent Us from erasing Personal Data when personal data are no longer necessary for the purposes but still required for your legal claims or when our processing is unlawful. Restriction of processing may lead to fewer possibilities to use our Browser.',
  // 'pp75': 'Right to data portability.',
  // 'pp76': 'You have the right to receive your Personal Data from Us in a structured, commonly used and machine-readable format and to independently transmit those data to a third party, in case our processing is based on your Consent and carried out by automated means.',
  // 'pp77': 'How to exercise these rights.',
  // 'pp78': 'To exercise any of the above-mentioned rights, you should primarily use the functions offered by our Browser. If such functions are however not sufficient for exercising such rights, you shall send Us an email to {email}, stating the following information: name, address, phone number, email address, and a copy of a valid proof of identity. We may request additional information necessary to confirm your identity. We may reject requests that are unreasonably repetitive, excessive or manifestly unfounded.',
  // 'pp79': 'STORING AND DELETION OF INFORMATION',
  // 'pp80': 'We store your Personal Data for as long as necessary to provide you with the Browser services. We may store data for longer periods, but only in such a way that it cannot be traced back to you. When personal data is no longer needed, We delete it using reasonable measures to protect the data from unauthorized access or use.',
  // 'pp81': 'We store Personal Data for as long as it is necessary to ensure the operation of the Browser. Traffic information is deleted or anonymized when it is no longer needed to carry out the transfer process or, in the case of a paid subscription, until the end of the period during which the account can be legally disputed or payment made. Information about targeted marketing and the provision of value-added software (including traffic information used for these purposes) is kept for as long as it is necessary to carry out such activities, or until the User refuses such use to Us in accordance with this Policy. The rest of the information is stored for as long as We consider it necessary to ensure the operation of our Browser. This section does not exclude the possibility of technical storage or access to information for the limited purpose of transmitting a message or in cases where it is absolutely necessary for Us to operate the Browser.',
  // 'pp82': 'EU territory. In accordance with the GDPR, We strive to anonymize data whenever possible. If you choose to exercise your right to erasure, We will also inform our providers to delete all of your data.',
  // 'pp83': 'We do not use the Browser to knowingly obtain information about children under 16 years of age. In the event that We become aware that We have collected personal data from a child under 16 years of age, We will delete that information as soon as possible. If you believe that We may have any information about children under 16 years of age, please contact Us at {email}.',
  // 'pp84': 'US territory.',
  // 'pp85': 'We do not use the Browser to knowingly obtain information about children under the age of 13. In the event We become aware that We have collected personal data from a child under 13, We will delete that information as soon as possible. If you believe that We may have any information about children under 13 years of age, please contact Us at {email}.',
  // 'pp86': 'Territory of Russia.',
  // 'pp87': 'We do not use the Browser to knowingly obtain information about children under the age of 14. In the event that We become aware that We have collected personal data from a child under 14 years of age, We will delete that information as soon as possible. If you believe that We may have any information about children under the age of 14, please contact Us at {email}.',
  // 'pp88': '8. INFORMATION SECURITY',
  // 'pp89': 'We care to ensure the security of Personal Data. We follow generally accepted industry standards to protect the information submitted to Us, both during transmission and once We receive it. We maintain technical, physical, and administrative security measures to provide reasonable protection for your Personal Data. When We or our contractors process your information, We also make sure that your information is protected from unauthorized access, loss, manipulation, falsification, destruction or unauthorized disclosure. This is done through appropriate administrative, technical and physical measures.',
  // 'pp90': 'There is no 100% secure method of transmission over the Internet or method of electronic storage. Therefore, We cannot guarantee its absolute security.',
  // 'pp91': 'We never process any kind of sensitive data and criminal offense data. Also, We never undertake to profile personal data.',
  // 'pp92': '9. SUPPLIERS',
  // 'pp93': 'We work with third party service providers who provide us with website development, application development, hosting, maintenance and other services. They may be located outside the EEA. These providers may access or process personal data on our behalf as part of providing these services to Us. We provide suppliers with information only to the extent that is reasonably necessary for them to perform their functions.',
  // 'pp94': 'All data transfer is carried out in accordance with the highest security standards. The transfer of personal data to countries outside the European Economic Area is possible only if We have received Your Consent to do so.',
  // 'pp95': 'All data processed by Us is stored exclusively on secure hosting provided by mcs.mail.ru, Yandex.Cloud or AWS Amazon.',
  // 'pp96': 'We use Amplitude Analytics to analyze User behavior and understand how Users navigate the Browser and which features appeal to them the most. We share the following information:',
  // 'pp97': 'personal email;',
  // 'pp98': 'IP address;',
  // 'pp99': 'operating system and its interface;',
  // 'pp100': 'information about user actions in the Browser;',
  // 'pp101': 'data on the type of subscription and payment methods.',
  // 'pp102': "For more information on Amplitude Analytics' privacy practices, please see the Privacy Policy: https://amplitude.com/privacy.",
  // 'pp103': "We use Yandex Metrica to evaluate site traffic and analyze user behavior. For more information about Yandex Metrika's privacy practices, please read the Privacy Policy: https://metrica.yandex.com/about/info/privacy-policy.",
  // 'pp104': "We use Google Analytics to generate detailed website visitor statistics. Statistics are collected on the Google server; The user only places the JS code on the pages of his site. The tracking code is triggered when the User opens the page in their web browser. For more information about the privacy practices of Google Analytics, please see the Privacy Policy: https://policies.google.com/privacy?hl=en-US s-privacy-policy/.",
  // 'pp105': "We use Cloudpayments to accept payments from our users. For more information about Cloudpayments' privacy practices, please see the Privacy Policy: https://static.cloudpayments.ru/docs/policy-cloudpayments.pdf.",
  // 'pp106': "We use Unlimitt to accept payments from our users. For more information about Unlimitt's privacy practices, please see the Privacy Policy: https://www.unlimint.com/privacy-policy/.",
  // 'pp107': "We use Cryptadium to accept payments from our users. For more information about Cryptadium's privacy practices, please see the Privacy Policy: https://cryptadium.com/uploads/docs/ru/FgQTFkUaoa5Ake72h-5Prae9NGokO9Fs_b.pdf.",
  // 'pp108': '10. OPT-OUT',
  // 'pp109': 'You can deactivate local notifications by changing the notification settings in accordance with the instructions of the operating system running on the Users’ device.',
  // 'pp110': '11. APPLICATION OF THIS PRIVACY POLICY',
  // 'pp111': 'This Privacy Policy is applicable to our Browser. Once redirected to another website or application, this Policy shall be no longer applicable.',
  // 'pp112': '12. ACCEPTANCE OF THE POLICY',
  // 'pp113': 'We assume that all Users have carefully read this document and agree to its content. If one does not agree with this Policy, they should refrain from using Browser.',
  // 'pp114': 'CHANGES AND UPDATES TO OUR PRIVACY POLICY',
  // 'pp115': 'From time to time, We may update this Privacy Policy. We encourage you to periodically check back and review this Policy so that you always will know what information We collect, how We use it, and with whom We share it.',
  // 'pp116': '14. CONTACT US',
  // 'pp117': 'If you have any questions please contact Us at support@Mogic37.ru.com.'
};
